import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ResponsiveDiv from "../components/responsivediv"

const NotFoundPage = (data) => (
  <>
    <Layout title="404">
      <ResponsiveDiv>
        <ReactMarkdown children={data.data.allStrapiNotFoundPage.nodes[0].text} />
      </ResponsiveDiv>
    </Layout>
  </>
)

export const query = graphql`
  {
    allStrapiNotFoundPage {
      nodes {
        text
      }
    }
  }
`

export default NotFoundPage
